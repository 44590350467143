/* Fonts Import Sofia Pro */
@font-face {
    font-family: 'Sofia Pro';
    src: url('/assets/fonts/sofia-pro/Sofia-Pro-ExtraLight.woff2') format('woff2');
    font-weight: 200;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'Sofia Pro';
    src: url('/assets/fonts/sofia-pro/Sofia-Pro-Light.woff2') format('woff2');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'Sofia Pro';
    src: url('/assets/fonts/sofia-pro/Sofia-Pro-Regular.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}
/* Fonts Import Aeonik*/
@font-face {
    font-family: 'Aeonik';
    src: url('/assets/fonts/aeonik/Aeonik-Light.woff2') format('woff2');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}
@font-face {
    font-family: 'Aeonik';
    src: url('/assets/fonts/aeonik/Aeonik-Regular.woff2') format('woff2');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}